import request, { RequestError, noop, throwErr } from './utils/request'
import Variable from './utils/variable'
import { payPlatform as platform } from './utils/platform'
import { isIOS, isInApp, getAppInfo } from './utils'
import { getQueryParameter, removeURLParameter } from './utils'
import {getPresetClientId, getPresetMainUrl} from './session/preset'

const GlobalVariable = new Variable().GlobalVariable

// 支持 share.lizhiweike.com 域名
if (/share\.lizhiweike\.com/.test(location.href)) {
    (window as any).clientId = (window as any).clientId || 'weike-html5-share'
} else if (/m\.weike\.fm/.test(location.href)) {
    (window as any).clientId = (window as any).clientId || 'weike-frontend2'
}

const presetClientId = getPresetClientId()
if (presetClientId) {
    (window as any).clientId = (window as any).clientId || presetClientId
}
// 在iframe内拦截为授权登录
const inIframeProxyNotAuthLogin = (window as any).inIframeProxyNotAuthLogin || false
/**判断是否使用新版的登陆 */
const oauth3: boolean = (window as any).oauth3 || false

const clientId: string
    = (window as any).clientId
    || 'weike-html5-frontend'

// 微课登录模式收拢成一个字段，默认使用中转页。
const WkLoginModeStr: 'silentLoginNotVisitor'|'isLoginDirectThansferMode'|'isLoginSnapshotMode'|'silentLogin' = (window as any).WkLoginModeStr || 'silentLoginNotVisitor'
// TODO：不同登录模式混用或灰度时可能会有冲突而导致异常。
/**
 * 使用游客账号的静默登录
 * 新用户返回游客身份，老用户静默后直接返回微课账号
 */
const silentLogin: boolean
    = platform === 'weixin'
        ? (WkLoginModeStr === 'silentLogin' || false)
        : false
/**
 * 不使用游客账号的静默登录（跳中转页）
 * 【配置为true后，新用户返回游客身份后跳中转页授权，老用户静默后直接返回微课账号】
 * 优点：正常情况不出现快照且只需一次微信授权，缺点：对新用户不太友好，跳转较多，有优化空间。
 */
const silentLoginNotVisitor: boolean = platform === 'weixin'  ? (WkLoginModeStr === 'silentLoginNotVisitor' || false) : false

/**
 * 使用快照模式《流程文档：https://o5lowswfbs.feishu.cn/wiki/wikcnXIWyB95nbJZDIAsSVxc3bc》
 * 【配置为true后，用户只需要在快照页微信授权一次，之后通过静默直接获取微课账号】
 * 问题：1. 安卓新用户访问可能不出现快照页，会直接调起授权 和 ios 行为不统一
 * 2. 快照内的用户为游客身份，显示的内容具有不确定性。
 */
const isLoginSnapshotMode: boolean = platform === 'weixin'  ? (WkLoginModeStr === 'isLoginSnapshotMode' || false) : false
/**
 * 跳中转页模式(暂不支持静默查找微课账号，待后台实现 或 使用silentLoginNotVisitor模式)
 * 【配置为true后，未授权或授权失效直接跳中转页】
 * 220712前的最初版
 */
const isLoginDirectThansferMode: boolean = platform === 'weixin'  ? (WkLoginModeStr === 'isLoginDirectThansferMode' || false) : false
export interface ISessionData {
    id_token?: string
    id: number
    expiredAt: number
    account?: Account
    token: string
    refresh_token?: string
}

export interface Account {
    avatar_url: string
    id: number
    nickname: string
    role: 'lecturer' | 'student'
    sex: '1' | '2'
    status: 'normal'
    subscribed: 0 | 1
    telephone_validate: boolean
}

const SessionConfig = {
    baseURL: GlobalVariable.SESSION_BASE_URL,
    clientId
}

// const Config = createConfig('Session', SessionConfig)
const Config = SessionConfig

interface IFetchPromise {
    onSuccess?(sessionData: ISessionData): any

    onFailed?(error: SessionError): any
}
class SessionError extends Error {
    public readonly data: RequestError | { code: number, data: any }

    constructor(data: SessionError['data']) {
        super(data instanceof RequestError
            ? `[Session] Error with status ${data.status}`
            : `[Session] Error with code ${data.code}`)
        this.data = data
    }
}

export type FetchOnSuccess = (sessionData: ISessionData) => any
export type FetchOnFailed = (error: SessionError) => any

// 是否强制避免使用 code 方式登录
const DO_NOT_USE_CODE = false
// 根据预设选择中转页跳转的地址
const getMainUrl = (window as any).wkLoginThansferDomain || getPresetMainUrl() || 'https://m1.lizhiweike.com'

const loginUrl = oauth3  ? '/oauth3/login'  : '/oauth2/authorize'

export default class Session {

    private fetchPromises: IFetchPromise[] = []
    private inFetch: boolean = false
    private sessionData: null | ISessionData = null
    private callbackQueue: IFetchPromise[] = []
    private isProcessing = false
    private refreshTokenTimer?: number
    private refreshRetryCount: number = 0
    private isRefreshRetryHasError = false

    constructor() {
        this.getSessionDataFromAuthorizeCode()
        this.getWkTokenFromMiniprogram()
    }

    /**
     * 供外部调用的 api, 获取 token 数据
     * 如果用户未登录，则会跳转到登录页面
     * 如果用户已登录，则会先去服务器获取 token 数据 (fetchToken)
     * 如果用户已登录，并且内存里有 sessionData，则会检测 token 是否已过期，未过期则直接返回,过期则会删除本地数据，重新获取或登录
     * @param {FetchOnSuccess} [onSuccess=noop]
     * @param {FetchOnFailed} [onFailed=throwErr]
     * @memberof Session
     */
    public getToken(onSuccess: FetchOnSuccess = noop, onFailed: FetchOnFailed = throwErr) {

        // app登陆，不再走h5授权，直接使用app登陆信息。
        if(isInApp() && /lihua-act.tryweike.net/.test(location.origin)){ // 年前特例，年后统一处理（过测试）
            if (this.isProcessing) {
                this.callbackQueue.push({onSuccess, onFailed})
                return
            }
            this.isProcessing = true
            getAppInfo("getUserInfo").then((appData:any)=>{
                const appDataInfo = !appData.exp_ts ? { ...appData, exp_ts: 18000 } : appData
                this.isProcessing = false
                this.sessionData = this.setupSessionData(appDataInfo)
                if (this.callbackQueue.length) {
                    this.callbackQueue.forEach(promise => promise.onSuccess && this.sessionData && promise.onSuccess({ ...this.sessionData }))
                }
                onSuccess({...this.sessionData})
            })
            return;
        }

        // 常规登陆
        if (
            this.sessionData && (
                (this.isTokenExpired() && this.isRefreshRetryHasError) ||  // 过期但是重试失败则直接使用已经过期的 token
                !this.isTokenExpired()
            )
        ) {
            onSuccess({ ...this.sessionData })
        } else {
            if (this.sessionData && this.isTokenExpired(this.sessionData)) {
                this.clear()
            }
            if (this.isProcessing) {
                this.callbackQueue.push({onSuccess, onFailed})
                return
            }
            this.isProcessing = true
            this.fetchToken((newSessionData) => {
                this.isProcessing = false
                this.sessionData = newSessionData
                onSuccess({ ...this.sessionData })
                this.storeToken()
                if (this.callbackQueue.length) {
                    this.callbackQueue.forEach(promise => promise.onSuccess && promise.onSuccess({ ...newSessionData }))
                }
            }, (error) => {
                // 404: code is invalid./ 405 code is reused.
                if (
                    !(error.data instanceof RequestError) &&
                    (error.data.code === 404 || error.data.code === 405) &&
                    (window as any).notVerifyCode
                  ) {
                    return
                  } else if ((error.data instanceof RequestError && error.data.status === 401)
                    || (!(error.data instanceof RequestError) && error.data.code !== 0)) {
                      // iframe中由父窗口接管登录
                    if(inIframeProxyNotAuthLogin && (window.top !== window.self)) {
                      window.parent && window.parent.postMessage({ action: 'wk_login_not_auth', href: window.location.href }, '*')
                      return
                    }
                    if (silentLogin || silentLoginNotVisitor) {
                        this.silentLogin()
                    } else {
                        this.login()
                    }
                } else if (onFailed === noop) {
                    throw error
                } else {
                    onFailed(error)
                    if (this.callbackQueue.length) {
                        this.callbackQueue.forEach(promise => promise.onFailed && promise.onFailed(error))
                    }
                }
            })
        }
    }
    // 获取中转页地址
    private getThansferUrl(redirectUri?: string){
      const type = DO_NOT_USE_CODE ? 'cookie' : 'w_code'
      const locationWithoutIdToken = removeURLParameter('id_token')
      const uri = redirectUri || locationWithoutIdToken
      const search = `?client_id=${clientId}&response_type=${type}&redirect_uri=${encodeURIComponent(uri)}`
      // 跳转中转页的处理：
      const reg = /^(https?:)\/\/([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i
      const arr = uri && reg.exec(uri) || []
      let domain = getMainUrl
      // 本地未登录跳测试环境
      if(/localhost|(\d+\.\d+\.\d+\.\d+)|(dbg\.(lizhiweike|weike|liveweike)\.)/.test(location.hostname)) {
        domain = 'https://dbg.lizhiweike.com'
      }
      return `${domain}/power${arr[4]||'/'}${search}`
    }
    private gotoAuthThansfer(redirectUri?: string, isForceClear?: boolean){
      // 微信环境才跳中转页
      if(navigator && /micromessenger/i.test(navigator.userAgent)) {
        const getUrl = this.getThansferUrl(redirectUri)
        if(isForceClear) {
          // 同时清除cookie和本地缓存
          this.logout(getUrl)
          return
        }
        // // 跳中转页
        // if(silentLoginNotVisitor) {
        //   // 未授权 退出登录并跳中转页
        //   this.logout(`${domain}/power${arr[4]||'/'}${search}`)
        // } else {
        //   // 直接跳中转页
        //   location.assign(`${domain}/power${arr[4]||'/'}${search}`)
        // }
        location.assign(getUrl)
      }
    }
    public login(redirectUri?: string) {
        this.clear()
        if(isLoginDirectThansferMode) {
          // 必须先执行this.clear()，以确保每次都执行/access_token接口
          this.gotoAuthThansfer()
          return
        }
        
        const baseURL = `${Config.baseURL}${loginUrl}`
        // url 中的 id_token 可能回被用户篡改，非法的 id token 需要从 url 中删除
        const locationWithoutIdToken = removeURLParameter('id_token')
        const type = DO_NOT_USE_CODE ? 'cookie' : 'w_code'
        const uri = redirectUri || locationWithoutIdToken
        // tslint:disable-next-line
        let search = `?forcePopup=false&client_id=${clientId}&response_type=${type}&redirect_uri=${encodeURIComponent(uri)}`
        if(isLoginSnapshotMode) {
          search += `&scope=silent`
        }
        // + (silentLogin && '&scope=visitor' || '') // 静默授权支持
        location.assign(`${baseURL}${search}`)
    }

    public silentLogin(redirectUri?: string) {
        this.clear()
        const baseURL = `${Config.baseURL}${loginUrl}`
        // url 中的 id_token 可能回被用户篡改，非法的 id token 需要从 url 中删除
        const locationWithoutIdToken = removeURLParameter('id_token')
        const type = DO_NOT_USE_CODE ? 'cookie' : 'w_code'
        // 用thansfer来区分中转页模式，以确保埋点的准确性（除此之外和用visitor的登录效果完全一致）
        const scope = silentLoginNotVisitor ? 'thansfer' : 'visitor'
        // tslint:disable-next-line
        // silentLoginNotVisitor模式下新用户会在后端拼接中转页地址（根据clientId和redirect_uri）
        const getUrl = (redirectUri || locationWithoutIdToken)
        const search = `?client_id=${clientId}&response_type=${type}&scope=${scope}&redirect_uri=${encodeURIComponent(getUrl)}` // 静默授权支持
        location.assign(`${baseURL}${search}`)
    }

    public logout(redirectUri?: string) {
        this.clear()
        const baseURL = `${Config.baseURL}/oauth2/clear`
        const search = `?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri || location.href)}`
        location.assign(`${baseURL}${search}`)
    }

    public clear() {
        this.clearTokenStore()
        this.sessionData = null
    }

    private promisesAllSuccess(sessionData: ISessionData) {
        this.fetchPromises.forEach(({ onSuccess }) => onSuccess ? onSuccess(sessionData) : void (0))
        this.fetchPromises = []
        this.inFetch = false
    }

    private promisesAllFailed(error: SessionError) {
        this.fetchPromises.forEach(({ onFailed }) => onFailed ? onFailed(error) : void (0))
        this.fetchPromises = []
        this.inFetch = false
    }

    private isTokenExpired(session: ISessionData | null = this.sessionData): boolean {
        return !session || Date.now() > session.expiredAt
    }

    private storeToken() {
        if (this.sessionData) {
            const { token, expiredAt, id, id_token, account, refresh_token } = this.sessionData
            localStorage.setItem('Session/token', token)
            localStorage.setItem('Session/expiredAt', expiredAt.toString())
            localStorage.setItem('Session/id', id.toString())
            localStorage.setItem('Session/id_token', id_token as string)
            localStorage.setItem('Session/account', JSON.stringify(account))
            localStorage.setItem('Session/refresh_token', refresh_token as string)
        }
    }

    public loadToken(): ISessionData | null {
        const token = localStorage.getItem('Session/token')
        const expiredAtString = localStorage.getItem('Session/expiredAt')
        const expiredAt = expiredAtString ? (parseInt(expiredAtString, 10) || 0) : null
        const idString = localStorage.getItem('Session/id')
        const id = idString ? (parseInt(idString, 10) || 0) : null
        const idToken = localStorage.getItem('Session/id_token') as string
        const rawAccount = localStorage.getItem('Session/account')
        const refreshToken = localStorage.getItem('Session/refresh_token')
        let account;
        if (rawAccount) {
            try {
                account = JSON.parse(rawAccount)
            } catch (e) {}
        }

        if (token && expiredAt && id && refreshToken) {
            return { token, expiredAt, id, id_token: idToken, account, refresh_token: refreshToken }
        } else {
            this.clearTokenStore()
            return null
        }
    }

    private clearTokenStore() {
        Object.getOwnPropertyNames(localStorage).forEach((key) => /^Session\//.test(key)
            ? localStorage.removeItem(key)
            : void (0))
    }

    /**
     * 从服务器获取 token,
     * 如果url 中有 code 使用 code 交换
     * 如果本地 localStorage 存储有 token, 则校验 token 合法
     * 最后尝试使用 cookie 中的 id_token(老方式，或 app)
     *
     * @private
     * @param {FetchOnSuccess} [onSuccess=noop]
     * @param {FetchOnFailed} [onFailed=throwErr]
     * @param {string} [type='cookie']
     * @param {string} [code]
     * @memberof Session
     */
    private fetchToken(onSuccess: FetchOnSuccess = noop, onFailed: FetchOnFailed = throwErr, type='cookie', code?: string) {
        this.fetchPromises.push({ onSuccess, onFailed })
        if (!this.inFetch) {
            this.inFetch = true
            // 从服务器获取 session 数据
            const getAccessTokenFromServer = (code?: string) => {
                this.getAccessTokenFromServer(code)
                    .catch(error => {
                        this.promisesAllFailed(new SessionError(error))
                        onFailed(new SessionError(error))
                        this.inFetch = false
                    })
                    .then(data => {
                        if (data) {
                            onSuccess(data)
                            this.promisesAllSuccess(data)
                        }
                        this.inFetch = false
                    })
            }
            // 如果 url 中有 code 直接从服务器获取 session 数据
            if (code) {
                getAccessTokenFromServer(code)
            // 如果是 app 登录，直接从 cookie 中取 id_token, 忽略本地存储的数据
            } else if (isInApp()) {
                getAccessTokenFromServer()
            } else {
                // 如 url 中没有 code, 首先从本地获取 session(并且检查 access_token 是否过期)
                // 如果 本地没有 则尝试通过 cookie 中的 id_token (兼容 app 登陆)
                this.getAccessTokenFromLocal().catch(getAccessTokenFromServer).then(data => {
                    if (data) {
                        onSuccess(data)
                        // this.promisesAllSuccess(data)
                    }
                    this.inFetch = false
                })
            }
        }
    }

    /**
     * 使用 code 换取 id token, 同时删除 url 中的 code
     *
     * @private
     * @memberof Session
     */
    private getSessionDataFromAuthorizeCode() {
        // 非 lizhiweike 的域名走 code 方式
        if (DO_NOT_USE_CODE) {
            return
        }
        const code = getQueryParameter('code')
        if (!code) {
            return
        }
        const onSuccess = (newSessionData: ISessionData) => {
            this.sessionData = newSessionData
            this.storeToken()
            // hack 更新 url 的 code 后 重新刷新 wx jssdk 配置
            if (isIOS()) {
                const locationWithoutIdToken = removeURLParameter('code')
                window.location.replace(locationWithoutIdToken)
            }
        }
        // 使用 code 换取 id token
        this.fetchToken(onSuccess, noop, 'w_code', code)
        // 删除 url 中的 code
        const locationWithoutIdToken = removeURLParameter('code')
        history.replaceState(null, '', locationWithoutIdToken)
    }

    /**
     *检查 accessToken 是否已失效
     *
     * @private
     * @return {*}  {Promise<boolean>}
     * @memberof Session
     */
    private checkAccessToken(sessionData: ISessionData): Promise<{is_valid: boolean, sessionData: ISessionData}> {
        const { token, id } = sessionData
        if (!token) {
            return Promise.reject()
        }
        // hack：有0.2%的用户存在游客缓存，强制清除本地缓存及cookie并跳中转页（问题集中在iphone手机上，游客身份登录的原因待排查）
        if(silentLoginNotVisitor && /^84840084|3000000$/.test(id + '')) {
          this.gotoAuthThansfer('', true) // 注释该句只会清除本地缓存，不会清除cookie
          return Promise.reject()
        }
        const baseUrl = Config.baseURL
        const url = `/oauth2/check_token?token=${token}`
        return new Promise((resolve, reject) => {
            request({ baseUrl, url, method: 'GET', withCredentials: true }, (result) => {
                const { code, data } = result
                if (code !== 0) {
                    return reject()
                }
                if (!data.is_valid) {
                    return reject()
                }
                let { now_ts } = data
                if (!now_ts) {
                    now_ts = Date.now()
                }
                const { expiredAt } = sessionData
                if (!expiredAt) {
                    return reject()
                }
                const duration = expiredAt - now_ts
                if (duration <= 0) {
                    return reject()
                }
                resolve({ is_valid: data.is_valid, sessionData: {...sessionData, account: data.account || sessionData.account }})
                this.setupRefreshTokenTimer(duration)
                // 更新本地的 account 数据
                if (data.account) {
                    this.updateAccountData(data.account)
                }
            }, reject)
        })
    }

    /**
     * 用 code 或者 cookie 中的 id_token 获取取 session 信息
     *
     * @private
     * @param {string} code
     * @return {*}  {Promise<ISessionData>}
     * @memberof Session
     */
    private getAccessTokenFromServer(code?: string): Promise<ISessionData> {
        return new Promise((resolve, reject) => {
            const baseUrl = Config.baseURL
            let url = `/oauth2/access_token?client_id=${Config.clientId}&grant_type=cookie`
            const idToken =  getQueryParameter('id_token')
            // 此处visitor在后台无用
            if (silentLogin || silentLoginNotVisitor) {
                url += `&visitor=1`
            }
            if (code) {
                url += `&code=${code}`
            }
            if (idToken) {
                url += `&id_token=${idToken}`
            }
            request({ baseUrl, url, method: 'GET', withCredentials: true }, (result) => {
                if (result.code !== 0) {
                    reject(result)
                    return
                }
                // 静默登录返回游客身份则说明是新用户，直接跳转到中转页；
                // 84840084为最初的游客账号(但很多接口被限制访问)，3000000为快照模式使用的游客账号
                if(silentLoginNotVisitor && result.account && /^84840084|3000000$/.test(result.account.id)) { // 加条件测试&& !localStorage.getItem('force_auth_transfer')
                  this.gotoAuthThansfer('', true) // 如果其它项目都更新了session sdk, 可以直接调用this.gotoAuthThansfer(）、可减少一次清除cookie的跳转
                  reject(result)
                  return
                }
                const sessionData = this.setupSessionData(result)
                resolve(sessionData)
            }, reject)
        })
    }

    /**
     * 获取本地存储的 token 并校验是否过期
     *
     * @private
     * @return {*}  {Promise<ISessionData>}
     * @memberof Session
     */
    private getAccessTokenFromLocal(): Promise<ISessionData>  {
        const idToken =  getQueryParameter('id_token')
        if (idToken) { // 如果 url 中有 id token 则直接返回，通过 id token 登陆 (crm 登陆)
            return Promise.reject()
        }
        const token = this.loadToken()
        if (!token) {
            return Promise.reject()
        }
        if (!token.account) {
            // 兼容旧的登录数据
            // 旧的登录数据未储存 account 数据
            // 强制用户退出后重新登录
            this.clear()
            return Promise.reject()
        }
        if (this.isTokenExpired(token)) {
            this.clear()
            return Promise.reject()
        }
        return this.checkAccessToken(token)
        .then(validData =>  {
            if (!validData.is_valid) {
                this.clear()
                return Promise.reject()
            }
            this.sessionData = validData.sessionData
            return validData.sessionData
        }
        )
    }

    /**
     * 使用 refresh token 刷新 token
     *
     * @private
     * @return {*}  {Promise<ISessionData>}
     * @memberof Session
     */
    private refreshToken(): Promise<ISessionData> {
        return new Promise((resolve, reject) => {
            this.getToken(sessionData => {
                const { refresh_token } = sessionData
                if (!refresh_token) {
                    return reject('no refresh token')
                }
                const baseUrl = Config.baseURL
                const url = `/oauth2/access_token?refresh_token=${refresh_token}`
                request({baseUrl, url, method: 'GET', withCredentials: true}, result => {
                    if (result.code !== 0) {
                        this.retryRefreshToken(reject)
                        this.isRefreshRetryHasError = true
                        return
                    }
                    const tokenData = this.setupSessionData(result)
                    resolve(tokenData)
                }, () => {
                    this.retryRefreshToken(reject)
                    this.isRefreshRetryHasError = true
                })
            }, reject)
        })
    }

    /**
     * 刷新 token 重试，失败后，一分钟重试一次，重试 30 次之后不在重试
     *
     * @private
     * @param {(reason: any) => void} [reject]
     * @memberof Session
     */
    private retryRefreshToken(reject?: (reason: any) => void) {
        if (this.refreshRetryCount >= 30) {
            reject && reject(new Error('retry error'))
        } else {
            this.refreshRetryCount++
            setTimeout(() => {
                this.refreshToken().then(() => {
                    this.refreshRetryCount = 0
                    this.isRefreshRetryHasError = false
                })
            }, 1000 * 60)
        }
    }

    /**
     * 设置刷新 token 定时器
     *
     * @private
     * @param {number} [duration=0]
     * @memberof Session
     */
    private setupRefreshTokenTimer(duration: number = 0) {
        if (this.refreshTokenTimer) {
            window.clearTimeout(this.refreshTokenTimer)
        }
        this.refreshTokenTimer = window.setTimeout(() => {
            this.refreshToken()
        }, duration)
    }

    /**
     * 从接口中获取到 session 数据后，统一处理存储和设置刷新倒计时
     *
     * @private
     * @param {*} result
     * @memberof Session
     */
    private setupSessionData(result: any) {
        const { access_token: token, account ,id_token, exp_ts, now_ts, refresh_token } = result
        const sessionData = {
            account,
            token,
            expiredAt: (exp_ts - 60 * 30) * 1000 + now_ts,  // exp_ts 单位是秒，now_ts 是毫秒, 前端提前半个消失过期刷新
            id: account.id,
            id_token,
            refresh_token
        }
        this.setupRefreshTokenTimer((exp_ts - 60 * 30) * 1000)
        this.sessionData = sessionData
        this.storeToken()
        // @TODO: 兼容沙雕旧 weike-fe 逻辑，之后要下线的，所有
        localStorage['lzwk_id'] = sessionData.id
        return sessionData
    }

    // check token 之后 更新 session 数据
    private updateAccountData(account: Account) {
        if (!this.sessionData) {
            return
        }
        this.sessionData = {
            ...this.sessionData,
            account
        }
        localStorage.setItem('Session/account', JSON.stringify(account))
    }

    /**
     * 获取用户实时信息时，直接刷新 token 获取最新用户信息
     *
     * @return {*}  {Promise<Account>}
     * @memberof Session
     */
    getUserInfo(): Promise<Account> {
        return this.refreshToken().then(session => session.account as Account)
    }


    /**
     * 判断在微信小程序的环境中,并且有wk_token的参数,则存起来
     * 
     * 判断条件: 
     *  1. 在微信小程序web-view里打开
     *  2. 有wk_token的参数 (目前仅有群控万能活码页会带wk_token)
     * 
     * 需求背景:
     *      群控万能活码页(https://m.lizhiweike.com/activity2/universal_live_code/:id/:code),因为登录token和微课token所属体系不同, 
     *  导致嵌入微信小程序后, 在已授权并已知微课token的情况下, 仍然需要在万能活码页进行二次授权. 第二次授权是通过授权后路由重新定向完成的, 但是
     *  ios系统的微信端有已知的白屏的问题, 所以导致ios并不能正确使用.
     * 
     * 解决方案:
     *   1.在微信小程序端web-view判断为群控万能活码页则携带wk_token, 同样群控万能活码页判断为相同条件则解析微课token后直接返回二维码,避免二次授权带来的白屏问题
     *   2.session项目优化, 原理相同, 在同样场景下H5页面的localStorage中保存微课token
     *
     * @private
     * @memberof Session
     */
     private getWkTokenFromMiniprogram() {
        const miniprogram_wk_token = getQueryParameter('wk_token')
        if (!miniprogram_wk_token) return
        if ((window as any).__wxjs_environment !== 'miniprogram') return
        // 删除 url 中的 wk_token
        const locationWithoutIdToken = removeURLParameter('wk_token')
        history.replaceState(null, '', locationWithoutIdToken)
        localStorage.setItem('Session/miniprogram_wk_token', miniprogram_wk_token)
    }
}

